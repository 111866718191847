import React from 'react';
import App from 'Src/app/';
import Auth from 'Components/Auth';

const AuthPage = () => (
  <App lang="ru">
    <Auth />
  </App>
);

export default AuthPage;

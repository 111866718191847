/* eslint-disable consistent-return */
import { useContext } from 'react';
import isNode from 'detect-node';
import Cookie from 'js-cookie';
import MainContext from 'Src/app/MainContextProvider';
import useKeycloak from '../../hooks/useKeycloak';

const Auth = () => {
  const {
    state: { auth, token },
    dispatch,
  } = useContext(MainContext);

  const updateToken = updatedToken => {
    dispatch({ type: 'auth', payload: updatedToken });
  };

  if (!auth || !token) {
    useKeycloak(authData => {
      if (authData.authToken) Cookie.set('kctoken', authData.authToken);
      if (authData.refreshToken)
        Cookie.set('kcrefreshtoken', authData.refreshToken);

      if (authData && !auth) {
        if (authData.authToken !== '' && !token) {
          updateToken(authData.authToken);
        }
        if (!auth) {
          dispatch({
            type: 'auth',
            payload: {
              login: authData.login,
              logout: authData.logout,
              register: authData.register,
            },
          });
        }
      } else if (Cookie.get('VW_LOGOUT')) {
        const url = Cookie.get('VW_LOGOUT');
        Cookie.remove('VW_LOGOUT');
        return window.location.replace(url);
      }
    });
  } else {
    if (isNode) return null;

    const appUrl = process.env.GATSBY_US_REDIRECT;
    const authPath = Cookie.get('VW_AUTH') || appUrl;

    return window.location.replace(authPath);
  }

  return null;
};

export default Auth;
